/* ============ Common Css ============= */
.headTitle {
  padding-bottom: 30px;
  position: relative;
}
.headTitle h2 {
  text-transform: capitalize;
  font-family: var(---HeadFont);
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  padding-bottom: 10px;
}

.headTitle h2:before {
  border-bottom: 2px solid var(---primaryColor);
  content: "";
  font-family: var(---HeadFont);
  top: 70px;
  position: absolute;
  text-align: center;
  width: 100px;
  left: 50%;
  transform: translate(-50%, -25px);
}
.headTitle p {
  font-family: var(---SubHeading);
  margin-top: 20px;
  text-align: center;
  padding: 0px 80px;
}
@media (max-width: 768px) {
  .headTitle h2 {
    font-size: 26px;
    margin-top: 20px;
  }
  .headTitle p {
    padding: 0px 0px;
    text-align: justify;
  }
}
@media (max-width: 475px) {
  .headTitle h2 {
    font-size: 26px;
    margin-top: 30px;
  }
}

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
  line-height: inherit;
}

/* ========== Global Settings =========== */
h4 {
  color: #111;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #1b1c1e;
}

a:hover,
a:focus,
a:visited,
a:active,
button:active {
  text-decoration: none;
  outline: none;
}
textarea {
  overflow: hidden;
  resize: none;
}

button {
  outline: none !important;
  cursor: pointer;
}

p {
  font-size: 15px;
  line-height: 1.7em;
  font-weight: 400;
  margin: 0 0 15px;
}

.text {
  font-size: 15px;
  line-height: 1.7em;
  font-weight: 400;
  margin: 0 0;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.theme-btn {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.theme-btn i {
  position: relative;
  display: inline-block;
  font-size: 14px;
  margin-left: 3px;
}

.centered {
  text-align: center !important;
}

/*Btn Style One*/

.btn-style-one {
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  border-radius: 0px;
  overflow: hidden;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.btn-style-one .btn-title {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #fff !important;
  font-weight: 500;
  padding: 15px 40px;
  background: #1b1c1e;
  border-radius: 0px;
  outline: 1px solid transparent;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.btn-style-one:hover .btn-title {
  background: #1b1c1e;
  outline-color: #ffffff;
  outline-offset: -4px;
  color: #ffffff;
}

/* ================ About Section ============== */
.about-section {
  position: relative;
  padding: 0px 0px 60px;
  background: #edf0f5;
}
.pattern-top {
  position: absolute;
  width: 100%;
  height: 90px;
  background: url(../img/theam/pattern-top.png) center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}

.pattern-bottom {
  position: absolute;
  width: 100%;
  height: 90px;
  background: url(../img/theam/bottom.png) center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}
.about-section .pattern-bottom {
  left: 0;
  top: 100%;
  margin-top: -10px;
}

.about-section .text-column {
  position: relative;
  order: 12;
  margin-bottom: 50px;
  z-index: 1;
}

.about-section .text-column .inner {
  position: relative;
  display: block;
  padding-top: 70px;
  padding-left: 45px;
}

.about-section .text-column .text {
  position: relative;
  font-size: 16px;
  color: #8e949f;
  line-height: 1.6em;
}

.about-section .text-column .link-box {
  position: relative;
  padding-top: 60px;
}

.about-section .text-column .link-box .theme-btn {
  min-width: 210px;
}

.about-section .image-column {
  position: relative;
  order: 0;
  margin-bottom: 50px;
  z-index: 1;
}

.about-section .image-column .inner {
  position: relative;
  display: block;
  padding-right: 30px;
}

.about-section .image-column .image-box {
  position: relative;
  padding-left: 150px;
}

.about-section .image-column .image {
  position: relative;
  display: block;
}

.about-section .image-column .image img {
  display: block;
  width: 100%;
}

.about-section .image-column .cap {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 100%;
  background: #1b1c1e;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
}

.about-section .image-column .cap .txt {
  position: absolute;
  left: 0;
  top: 37%;
  width: 100%;
  padding: 0px 10px;
  font-size: 30px;
  line-height: 1.5em;
  font-family: "Yeseva One", cursive;
}

/* =============== Rooms Section =========== */
.rooms-section-one {
  position: relative;
  padding: 140px 0px 0px;
  background: #ffffff;
  margin-bottom: 80px;
}

.room-block-one {
  position: relative;
  margin-bottom: 30px;
  z-index: 2;
}

.room-block-one .inner-box {
  position: relative;
  display: block;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
  overflow: hidden;
}

.room-block-one:hover .inner-box {
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.15);
}

.room-block-one .image-box {
  position: relative;
  display: block;
  background: #171b27;
}

.room-block-one.height-one .image-box {
  height: 420px;
}

.room-block-one.height-two .image-box {
  height: 854px;
}

.room-block-one .image-box img {
  display: none;
  width: 100%;
  opacity: 0.8;
}

.room-block-one .image-box .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.room-block-one:hover .image-box .image-layer {
  opacity: 0.5;
}

.rooms-section-one .row {
  margin: 0px -7px;
}

.rooms-section-one .column {
  padding: 0px 7px;
}

.rooms-section-one .room-block-one {
  margin-bottom: 14px;
}

.room-block-one .cap-box {
  position: absolute;
  left: 30px;
  bottom: 30px;
  right: 30px;
  max-width: 350px;
  opacity: 1;
  -webkit-transition: all 500ms ease 200ms;
  -moz-transition: all 500ms ease 200ms;
  -ms-transition: all 500ms ease 200ms;
  -o-transition: all 500ms ease 200ms;
  transition: all 500ms ease 200ms;
}

.room-block-one:hover .cap-box {
  opacity: 0;
  -webkit-transform: translateY(200px);
  -ms-transform: translateY(200px);
  transform: translateY(200px);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.room-block-one .cap-box .cap-inner {
  position: relative;
  display: block;
  padding: 18px 20px 18px 40px;
  background: #ffffff;
}

.room-block-one .cap-box .cap-inner:before {
  content: "";
  position: absolute;
  right: 0;
  top: -15px;
  border: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
  border-right: 8px solid #ffffff;
}

.room-block-one .cap-box .price {
  position: relative;
  display: block;
  line-height: 24px;
  color: #8e949f;
  text-transform: uppercase;
  font-weight: 500;
}

.room-block-one .cap-box .price span {
  color: #1b1c1e;
}

.room-block-one .cap-box h5 {
  position: relative;
  display: block;
  margin: 0;
}

.room-block-one .hover-box {
  position: absolute;
  left: 30px;
  bottom: 30px;
  right: 30px;
  max-width: 350px;
  opacity: 0;
  -webkit-transform: translateY(-102%);
  -ms-transform: translateY(-102%);
  transform: translateY(-102%);
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.room-block-one:hover .hover-box {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 500ms ease 200ms;
  -moz-transition: all 500ms ease 200ms;
  -ms-transition: all 500ms ease 200ms;
  -o-transition: all 500ms ease 200ms;
  transition: all 500ms ease 200ms;
}

.room-block-one .hover-box .hover-inner {
  position: relative;
  display: block;
  padding: 35px 40px 40px 40px;
  background: #ffffff;
  height: 100%;
}

.room-block-one .hover-box h4 {
  position: relative;
  margin-bottom: 15px;
}

.room-block-one.height-three .hover-box h4,
.room-block-one.height-four .hover-box h4 {
  font-size: 22px;
}

.room-block-one .hover-box .pricing {
  position: relative;
  line-height: 24px;
  margin-bottom: 20px;
}

.room-block-one .hover-box .pricing .price {
  position: relative;
  display: block;
  float: left;
  color: #8e949f;
  text-transform: uppercase;
  font-weight: 500;
}

.room-block-one .hover-box .pricing .price span {
  color: #1b1c1e;
}

.room-block-one .hover-box .pricing .rating {
  position: relative;
  display: block;
  float: right;
  color: #1b1c1e;
}

.room-block-one .hover-box .pricing .rating span {
  position: relative;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1px;
}

.room-block-one .hover-box h5 {
  position: relative;
  display: block;
  margin: 0 0 20px;
}

.room-block-one .hover-box .text {
  position: relative;
  display: block;
  color: #9b9fa6;
  font-size: 16px;
  line-height: 1.6em;
  margin: 0 0 30px;
}

.room-block-one .hover-box .link-box {
  position: relative;
  display: block;
}

.room-block-one .hover-box .link-box .theme-btn {
  display: block;
}

/* ======== Enquiry Modal ===== */
.react-datepicker-wrapper {
  width: 100%;
}
