
/* ======================================= Footer Section ============================== */

ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background:#111;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #fff;
  font-size: 25px;
  float: left;
  margin-top: 8px;
  background-color:var(---primaryColor);
  padding:7px;
}

.cta-text {
  padding-left: 48px;
  display: inherit;
}

.cta-text h4 {
  color:whitesmoke;
  font-size: 20px;
  /* font-weight: 600; */
  margin-bottom: 2px;
}

.cta-text span {
  color: #808080;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 130px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #808080;
  line-height: 28px;
}

.footer-social-icon span {
  color:whitesmoke;
  display: block;
  font-size: 20px;
  font-weight: 700;
     font-family: var(---HeadFont);
  margin-bottom: 20px;
}

.footer-social-icon a {
  
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  color: #fff;
}

.facebook-bg {
  background: #3b5998;
}

.twitter-bg {
  background: #55acee;
}

.google-bg {
  background: #dd4b39;
}
.watsapp-bg {
  background: #47c757;
}

.footer-widget-heading h3 {
  color: whitesmoke;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(---SecondColor);
}

.footer-text h5{
  color: #fff;
   font-size: 16px;
   font-weight: 600;
   position: relative;
 }

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  
}

.footer-widget ul li a {
  color: whitesmoke;
  text-transform: capitalize;
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #fff;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: var(---primeColor);
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.bottomNavbar .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
color: #808080 !important;
}

.bottomNavbar .MuiTabs-indicator{
display: none;
}

.bottomNavbar .MuiBox-root{
margin: auto;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
box-shadow: unset;
}
/*  Footer css */
.element-btn .element-fill-btn {
  background: none;
  border:1px solid red;

  color: #fff;
  padding: 13px 5px;
  font-size: 16px;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  width: 252px;
  margin: 0 auto;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 50px -10px rgba(255, 10, 120, 1);
 
}
.fill-btn {
  border:1px solid red;
  
  color: #fff;
  padding: 13px 5px;
  font-size: 16px;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  width: 252px;
  margin: 0 auto;
  border-radius: 25px;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
 background: none;
}
.fill-btn:hover,
.element-btn .element-fill-btn:hover {
  background-color: #000;
  box-shadow: 0px 10px 50px -10px rgb(32, 31, 31);
}


.footer {
  position: relative;
  background-color: #201f1f;
  padding:50px 0px;
}

.footerBg {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.13;
  background: url('http://placehold.it/1920x677') no-repeat top center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.form-Box input[type="text"],
.form-Box input[type="email"],
.form-Box input[type="number"],
.form-Box textarea {
  width: 100%;
  height: 50px;
  border: 1px solid #737373;
  border-radius: 25px;
  background-color: transparent;
  padding: 5px 25px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #737373;
  font-style: italic;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}
.form-Box textarea {
  height: 113px;
  padding: 15px 25px;
  resize: none;
}
.form-Box .b_effect:focus,
.subscribe form input:focus {
  border-color: #ff6600;
}

.form-Box .fill-btn {
  position: static;
  transform: translate(0%, 0);
  -webkit-transform: translate(0%, 0);
  -moz-transform: translate(0%, 0);
  -ms-transform: translate(0%, 0);
  width: 184px;
}

.form-Box .fill-btn:hover,
.subscribe form .fill-btn:hover {
  background-color: #000;
  color: #fff;
border:1px solid #fff;
}

.fTitle {
  font-weight: 900;
  color: #ffffff;
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 20px;
}

.fTitle span {
  color: #ff6600;
}

.contact-address ul li,
.contact-address ul li a {
  color: #737373;
  font-size: 16px;
  font-style: italic;
  margin-bottom: 15px;
  line-height: 32px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.contact-address {
  padding: 0 15%;
}

.contact-address ul li i {
  margin-right: 15px;
  display: inline-block;
  float: left;
  line-height: 32px;
}

.contact-address ul li span {
  display: table;
}

.contact-address ul li a:hover {
  color: #ff6600;
}

.subscribe form {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 60px;
}

.subscribe form input {
  width: 100%;
  height: 50px;
  border: 1px solid #737373;
  border-radius: 25px;
  background-color: transparent;
  padding: 5px 215px 5px 25px;
  font-size: 14px;
  color: #737373;
  font-style: italic;
}

.subscribe form .fill-btn {
  bottom: auto;
  top: 0;
  right: 0;
  left: auto;
  transform: translate(0%, 0);
  -webkit-transform: translate(0%, 0);
  -moz-transform: translate(0%, 0);
  -ms-transform: translate(0%, 0);
  width: 209px;
}

.social-icons ul li {
  display: inline-block;
  float: left;
  color: #fff;
  text-align: center;
  line-height: 43px;
  position: relative;
  width: 43px;
  height: 43px;
  margin-right: 28px;
}

.social-icons ul li a {
  color: #fff;
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 16px;
}

.social-icons ul li:before {
  content: "";
  width: 43px;
  height: 43px;
  display: inline-block;
  position: absolute;
  border: 1px solid #ff6600;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.social-icons ul li:last-child {
  margin: 0;
}

.social-icons ul li:hover:before {
  background: #ff6600;
}


