/* =========== Banner Section ======== */
.Room-section {
  position: relative;
  z-index: 1;
}

.Room-section .room-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  height: 120px;
  background: url(../img/theam/bottom-2.png) center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}
.room-Banner {
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}
.roombannerTxt h2 {
  color: #fff;
  font-size: 50px;
  font-family: var(---ItalicFont);
  font-weight: 600;
  text-align: left;
}

.roombannerTxt p {
  color: #fff;
  font-family: var(---SubHeading);
  text-align: left;
  font-size: 16px;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 768px) {
  .room-Banner {
    height: 60vh;
  }

  .roombannerTxt {
    padding:20px;
    margin-top: 10px;
  }
  .roombannerTxt h2 {
    color: #fff;
    font-size: 24px;
    font-family: var(---HeadFont);
    font-weight: 600;
    text-align: left;
  }
  .roombannerTxt p {
    color: #fff;
    font-family: var(---SubHeading);
    text-align: left;
    font-size: 16px;
  }
}

/* ========== Room Servies Detail1  ====== */
.room-services1 {
  margin-bottom: 100px;
}
.Room-Desc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Room-Txt h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}

.room-aminities h4 {
  color: #111;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.room-aminities span {
  color: var(---primaryColor);
  margin-right: 10px;
}

.wed-img {
    margin-bottom: 30px;
    height: 279px;
    /* width: 100%; */
    /* height: 100%; */

}
.Room-Img img {
  width: 100%;
  height: 100%;
  max-height: 600px;
}

/* ========== Room Servies Detail2  ====== */
.room-services2 {
  position: relative;
  background: #edf0f5;
  margin: 70px 0px 0px 0px;
}

.room-services2 .pattern-top {
  bottom: 100%;
  margin-bottom: 0px;
}

.Room-Desc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Room-Txt h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}

.room-aminities h4 {
  color: #111;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.room-aminities span {
  color: var(---primaryColor);
  margin-right: 10px;
}

.Room-Img {
  height: 340px !important;
  /* width: 100%;
  height: 100%; */
}
.Room-Img img {
  width: 100%;
  height: 100%;
  max-height: 600px;
}

/* ========== Room Servies Detail3 ====== */
.room-services3 {
  margin: 100px 0px 100px 0px;
}
.Room-Desc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Room-Txt h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}

.room-aminities h4 {
  color: #111;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.room-aminities span {
  color: var(---primaryColor);
  margin-right: 10px;
}

.Room-Img {
  width: 100%;
  height: 100%;
}
.Room-Img img {
  width: 100%;
  height: 100%;
  max-height: 600px;
}

/* ========== Room Servies Detail4  ====== */
.room-services4 {
  position: relative;
  background: #edf0f5;
  padding: 0px 0px 50px 0px;
}

.room-services4 .pattern-top {
  bottom: 100%;
  margin-bottom: 0px;
}
.Room-Desc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Room-Txt h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}

.room-aminities h4 {
  color: #111;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.room-aminities span {
  color: var(---primaryColor);
  margin-right: 10px;
}

.Room-Img {
  width: 100%;
  height: 100%;
}
.Room-Img img {
  width: 100%;
  height: 100%;
  max-height: 600px;
}
