/* =========== Banner Section ======== */
.about-section {
  position: relative;
  z-index: 1;
}

.about-section .about-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 120px;
  background: url(../img/theam/bottom-2.png)
    center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}
.aboutBanner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/banner/realestate-banner.jpg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}
.aboutbannerTxt h2 {
  color: #fff;
  font-size: 50px;
  font-family: var(---ItalicFont);
  font-weight: 600;
  text-align: left;
}

.aboutbannerTxt p {
  color: #fff;
  font-family: var(---SubHeading);
  text-align: left;
  font-size: 16px;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 768px) {
  .aboutBanner {
    height: 50vh;
  }

  .aboutbannerTxt {
    padding:10px;
    margin-top: 30px;
  }

  .aboutbannerTxt h2 {
    color: #fff;
    font-size: 24px;
    font-family: var(---HeadFont);
    font-weight: 600;
    text-align: left;
  }
  .aboutbannerTxt p {
    color: #fff;
    font-family: var(---SubHeading);
    text-align: left;
    font-size: 16px;
  }
}
/* ========== About Md  Section ==== */
.about-manager {
  padding: 50px 0px 50px;
  background: #ffffff;
  margin-bottom: 80px;
}

.manager-img {
  width: 100%;
  height: 100%;
  max-height: 350px;
  /* border: 2px solid gray; */
  box-shadow: 0 0 3px #808080;
  border-radius: 5px;
}
.manager-img img {
  width: 100%;
  height: 100%;
  /* padding: 20px; */
  /* background-color: #eee; */
  transform: scale(0.9);
}
/* .manager-img:hover img {
  transform: scale(1);
} */

.manager-Detail {
  margin-top: 20px;
}

.read-or-hide {
  color: blue;
}

.manager-desc h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}
@media (max-width: 768px) {
  .manager-desc h2 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
  }
}
@media (max-width: 350px){
.manager-desc h2 {
    margin-top: 70px;
    font-size: 20px;
    font-weight: 600;
}
}

/* ========== About Mision Vision Section ==== */
.about-mission {
  position: relative;
  background: #edf0f5;
}

.about-mission .pattern-top {
  bottom: 100%;
  margin-bottom: 0px;
}

.card-space:hover .visionCard {
  transform: rotateY(-180deg);
}

.visionCard {
  border-radius: 10px;
  height: 350px;
  position: relative;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
}
.face {
  border-radius: 10px;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
  position: absolute;
  height: 100%;
  width: 100%;
}
.face.front {
  text-align: center;
  z-index: 20;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(0deg);
}
.face.back {
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.visionCard i {
  font-size: 100px;
}

.about-missionVision{
  background-color: #fff;
  padding: 20px;
  min-height: 380px;
  box-shadow: 0 0 4px gray;
  margin-bottom: 30px;
}

.missionVision-txt h4{
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}

.missionVision-txt h6{
  font-size: 16px;
  font-weight: 600;
}

.missionVision-txt ul li span {
  color: var(---primaryColor);
  margin-right: 10px;
}
@media (max-width: 768px) {
  .about-missionVision{
    min-height: auto;
  }
}

/* ====== About Counter Section ==== */
.about-count {
  position: relative;
  padding: 10px 0px 50px;
  background: #ffffff;
  margin-bottom: 80px;
}
.counter.left_icn {
  padding-left: 73px;
  position: relative;
}
.counter {
  color: #111;
  font-family: var(---HeadFont);
  min-height: 60px;
  position: relative;
}
.counter.left_icn i {
  bottom: auto;
  color: #fff;
  left: 0;
  /* position: absolute; */
  top: 3px;
  background-color: var(---primaryColor);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.counter i,
.counter i:before {
  font-size: 30px;
}
.counter i {
  display: inline-block;
  line-height: 64px;
}
.counter span {
  display: block;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  margin: 10px 0 5px;
}

.counter h6 {
  color:grey;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}


@media screen and (max-width: 990px) {
  .counter.left_icn {
    padding-left: 60px;
    position: relative;
  }
  .counter.left_icn i {
    bottom: auto;
    color: #fff;
    left: 0;
    position: absolute;
    top: 3px;
    background-color: var(---primaryColor);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .counter i,
  .counter i:before {
    font-size: 30px;
  }
  .counter span {
    font-size:25px;
    line-height:25px;
  }
  .counter h6 {
    color: #111;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 768px) {
  .counter.left_icn {
    padding-left: 40px;
    position: relative;
  }
  .counter.left_icn i {
    bottom: auto;
    color: #fff;
    left: 0;
    position: absolute;
    top: 3px;
    background-color: var(---primaryColor);
    border-radius: 50%;
    width: 35px;
    height:35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .counter i,
  .counter i:before {
    font-size:25px;
  }
  .counter span {
    font-size:20px;
    line-height:20px;
  }
  .counter h6 {
    color: #111;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

/* @media screen and (max-width: 578px) {
  .counter.left_icn {
    padding-left: 40px;
    position: relative;
  }
  .counter.left_icn i {
    bottom: auto;
    color: #fff;
    left: 0;
    position: absolute;
    top: 3px;
    background-color: var(---primaryColor);
    border-radius: 50%;
    width: 30px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .counter i,
  .counter i:before {
    font-size:20px;
  }
  .counter span {
    font-size:18px;
    line-height:18px;
  }
  .counter h6 {
    color: #111;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }
} */