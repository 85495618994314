.nav-bar-component {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.navbar-brand {
  width: 60px;
  height: 60px;
}
.navbar-brand img {
  width: 100%;
  height: 100%;
  transform: scale(1.9, 1.2);
}

.NavBar {
  border-radius: 4px;
  background-color:rgba(255,255,255,0.5);
}

.NavBar-bg {
  background-color: #fff !important;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.navbar-collapse {
  margin-left: 200px;
}
.nav-link a {
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none;
  color:#111;
  font-family: var(---HeadFont);
  font-size: 16px;
  font-weight: 600;
}

.navbar-dark .navbar-nav .nav-link {
  text-align: left;
  font-family: var(---HeadFont);
}
.nav-link a:hover {
  font-family: var(---HeadFont);
  color: var(---primaryColor);
}

@media (max-width: 992px) {
  .NavBar {
    background-color:rgb(255,255,255);
  }
  .navbar-collapse {
    margin-left: 0px;
    /* background-color: var(---primaryColor); */
  }
  .Social-media-icons {
    display: none;
  }
  .nav-link a {
    padding-left: 12px;
    padding-right: 12px;
    text-decoration: none;
    color: #000;
    font-family: var(---HeadFont);
    font-size: 16px;
    font-weight: 500;
  }
}
@media (max-width: 574px) {
  .navbar-brand img {
    width: 100%;
    height: 100%;
    transform: scale(1.2, 1);
  }
}

/* =========== Hover Dropdown ======= */
.dorpdown {
  position: absolute;
  background: #fff;
  color: #111;
  border-radius: 5px;
  width: 200px;
  top: 100px;
  visibility: hidden;
  opacity: 0;
  z-index: -10;
  transition: 0.5s ease-in-out;
}

.dropdown_list {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.dropdown_list a {
  color:#111;
  font-family: var(---HeadFont);
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 5px 10px;
  text-decoration: none;
}

.dropdown_list a:hover {
  color:var(---primaryColor);
}

.dropdownShow {
  background: unset;
  border: unset;
  color: #000;
  font-family: var(---HeadFont);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 0px 10px 0px 10px;
}

.dropdownShow:focus .dorpdown,
.dropdownShow:hover .dorpdown {
  top: 70px;
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transition: 0.5s ease-in-out;
}

@media (max-width: 992px) {
  .dropdownShow {
    color:#111;
    font-family: var(---HeadFont);
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    padding: 0px;
  }
  .dropdownShow:focus .dorpdown,
  .dropdownShow:hover .dorpdown {
    top: 180px;
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transition: 0.5s ease-in-out;
  }
  .dropdown_list a {
    color:#111;
    font-family: var(---HeadFont);
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 5px 10px;
    text-decoration: none;
  }
}
/* =========== Header Section ============ */
.header {
  background-color: #000;
}

.head-Num{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.head-Num a{
  text-decoration: none;
  color: var(---primaryColor);
  font-size: 16px;
  font-family: var(---HeadFont);
}
@media (max-width: 992px) {
  .header {
    display: none;
  }
}
/* =========== Social Icons ========== */
.Social-icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  margin-right: 20px;
}
.rightIcon-list a .facebook {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid rgba(129, 129, 129, 0.5);
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #3b5998;
  color: #fff;
}

.rightIcon-list,
.rightIcon-list a .whatsapp {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid rgba(129, 129, 129, 0.5);
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #47c757;
  color: #fff;
}

.rightIcon-list,
.rightIcon-list a .instagram {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid rgba(129, 129, 129, 0.5);
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: linear-gradient(
    45deg,
    #f09433,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888
  );
  color: #fff;
}

.rightIcon-list,
.rightIcon-list a .youtube-play {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid rgba(129, 129, 129, 0.5);
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #f30c0c;
  color: #fff;
}

.rightIcon-list,
.rightIcon-list a .calling {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid rgba(129, 129, 129, 0.5);
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #04a9d3;
  color: #fff;
}

.rightIcon-list,
.rightIcon-list a .facebook:hover {
  background-color: transparent;
  color: #3b5998;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}

.rightIcon-list,
.rightIcon-list a .instagram:hover {
  background: none;
  color: #d92e7f;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}

.rightIcon-list,
.rightIcon-list a .youtube-play:hover {
  background-color: transparent;
  color: #f30c0c;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}

.rightIcon-list,
.rightIcon-list a .whatsapp:hover {
  background-color: transparent;
  color: #47c757;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}

.rightIcon-list,
.rightIcon-list a .calling:hover {
  background-color: transparent;
  color: #04a9d3;
  transform: scale(1.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
}
