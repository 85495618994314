/* =========== Banner Section ======== */
.real-estate{
    position: relative;
    z-index: 1;
  }
  
  .real-estate .real-estate-pattern {
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 100%;
    height: 120px;
    background: url(../img/theam/bottom-2.png)
      center bottom no-repeat;
    background-size: 100% 100%;
    z-index: 5;
  }
  .Realestate-Banner {
    height: 100vh;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: shrink 4s infinite;
  }
  .EstatebannerTxt h2 {
    color: #fff;
    font-size: 50px;
    font-family: var(---ItalicFont);
    font-weight: 600;
    text-align: left;
  }
  
  .EstatebannerTxt p {
    color: #fff;
    font-family: var(---SubHeading);
    text-align: left;
    font-size: 16px;
  }
  
  @keyframes shrink {
    0% {
      background-size: 115% 115%;
    }
    100% {
      background-size: 100% 100%;
    }
  }
  
  @media (max-width: 768px) {
    .Realestate-Banner {
      height: 60vh;
    }
  
    .EstatebannerTxt {
      padding: 20px;
      margin-top: 10px;
    }
    .EstatebannerTxt h2 {
      color: #fff;
      font-size: 24px;
      font-family: var(---HeadFont);
      font-weight: 600;
      text-align: left;
    }
    .EstatebannerTxt p {
      color: #fff;
      font-family: var(---SubHeading);
      text-align: left;
      font-size: 16px;
    }
  }

  /* ==========  */
  .realEstate-service{
    padding: 60px 0px 100px;
    margin-bottom: 30px;
  }
  .realEstate-Card{
   box-shadow: 0 0 4px gray;
   border-radius:3px;
  }
  .realEstate-Img{
    width: 100%;
    height: 100%;
  }
  .realEstate-Img img{
    width: 100%;
    height: 100%;
    max-height: 300px;
  }
  .realEstate-Desc{
    padding: 10px;
    height: 200px;
  }
  .realEstate-Desc h4{
    font-size: 20px;
  }
  .realEstate-Desc p{
    text-align: justify;
  }
  @media (max-width: 778px) {
    .realEstate-Desc{
      padding: 10px;
      height: auto;
    }
  }

  /* ======== Real Estate Project ==== */
  
.realEstate-Project{
    position: relative;
    background: #edf0f5;
    padding: 10px 10px 10px 10px;
  }
  
  .realEstate-Project .pattern-top {
      bottom: 100%;
      margin-bottom: 0px;
  }
  .realEstate-Project .pattern-bottom{
    top: 100%;
    margin-top: 0px;
}

.realHead{
  margin-top: 100px;
}
@media (max-width: 560px) {
  .realEstate-Project .realestateHead h2:before{
    margin-top: 30px;
}
}
@media (max-width: 370px) {
  .realEstate-Project .realestateHead h2:before{
    margin-top: 50px;
}
}

@media (max-width: 310px) {
  .realEstate-Project .realHead h2:before{
    margin-top: 30px;
}
}

  /* ======== Real Estate Project ==== */
  
  .realEstate{
    position: relative;
    background: #fff;
    margin-top:120px;
  }
  
  .realEstate .pattern-top {
      bottom: 100%;
      margin-bottom: 0px;
  }
  .realEstate-Project .pattern-bottom{
    top: 100%;
    margin-top: 0px;
}

.EstateProject-list ul li{
    line-height: 35spx;
}
.EstateProject-list ul li i{
  margin-right: 15px;
  color: var(---primaryColor);
}


/* ========== Estate Gallery ====== */
.Estate-Gallery{
  width: 100%;
  height: 100%;
}
.Estate-Gallery img{
  width: 100%;
  height: 100%;
}