/* =========== Banner Section ======== */
.activities-section {
  position: relative;
  z-index: 1;
}

.activities-section .activities-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  height: 120px;
  background: url(../img/theam/bottom-2.png)
    center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}
.activitiesBanner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/banner/activities-banner.jpg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}
.activitiesbannerTxt h2 {
  color: #fff;
  font-size: 50px;
  font-family: var(---ItalicFont);
  font-weight: 600;
  text-align: left;
}

.activitiesbannerTxt p {
  color: #fff;
  font-family: var(---SubHeading);
  text-align: left;
  font-size: 16px;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 768px) {
  .activitiesBanner {
    height: 60vh;
  }

  .activitiesbannerTxt {
    padding:20px;
    margin-top: 10px;
  }
  .activitiesbannerTxt h1 {
    color: #fff;
    font-size: 24px;
    font-family: var(---HeadFont);
    font-weight: 600;
    text-align: left;
  }
  .activitiesbannerTxt h2 {
    color: #fff;
    font-size: 24px;
    font-family: var(---HeadFont);
    font-weight: 600;
    text-align: left;
  }
  .activitiesbannerTxt p {
    color: #fff;
    font-family: var(---SubHeading);
    text-align: left;
    font-size: 16px;
  }
}
/* ======== */
.services-section {
  padding-bottom: 0;
  padding-top: 80px;
}
/* .services-item {
  border: 1px solid gray;
  box-shadow: 0 0 5px gray;
} */
.services-item .si-pic {
  height: 250px;
  position: relative;
  background-size: cover;
  background-position: center center;
}
.services-item .si-pic img {
  height: 100%;
  width: 100%;
}
.services-item .si-pic .service-icon {
  width: 85px;
  height: 85px;
  background: #fff;
  border: 1px solid gray;
  box-shadow: 0 0 2px gray;
  text-align: center;
  line-height: 89px;
  border-radius: 50%;
  position: absolute;
  bottom: -45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  left: 50%;
  -webkit-transform: translateX(-44.5px);
  transform: translateX(-44.5px);
}
.service-icon img {
  width: 100%;
  height: 100%;
}

.services-item .si-text {
  text-align: center;
  padding: 10px 10px 10px 10px;
  min-height: 30px;
  height: 100%;
}

.services-item .si-text h4 {
  margin-bottom: 10px;
}
.services-item .si-text p {
  text-align: justify;
}
@media (max-width: 1250px) {
  .services-item .si-pic {
    height: 293px;
    position: relative;
    background-size: cover;
    background-position: center center;
  }
  .services-item .si-pic img {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 990px) {
  .services-item .si-pic {
    height: 293px;
    position: relative;
    background-size: cover;
    background-position: center center;
  }
  .services-item .si-pic img {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 500px) {
  .services-item .si-pic {
    height: 293px;
    position: relative;
    background-size: cover;
    background-position: center center;
  }
  .services-item .si-pic img {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 990px) {
  .services-section {
    padding-bottom: 0;
    padding-top: 50px;
  }
}
@media (max-width: 750px) {
  .services-section {
    padding-bottom: 0;
    padding-top: 30px;
  }
}
@media (max-width: 500px) {
  .services-section {
    padding-bottom: 0;
    padding-top: 20px;
  }
}
