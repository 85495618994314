@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Noto+Serif+JP&display=swap');
:root {
  ---ItalicFont: 'Noto Serif JP', serif;
  ---HeadFont: "Roboto", sans-serif;
  ---SubHeading: "Lato", sans-serif;
  ---primaryColor:#d93135;
  ---SecondColor: #4e4d8e;
  ---lightColor: #fff;
  ---darkColor: #111;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:  #b30000; ; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: var(---HeadFont);
}
a {
  text-decoration: none !important;
  color: var(---darkColor);
  font-family: var(---HeadFont);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

ul li {
  text-decoration: none;
}
ul li {
  list-style: none;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 20px !important;
  height: 35px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---primaryColor);
}
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 20px !important;
  height: 35px !important;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(---primaryColor);
}
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: #ffff !important;
}
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  color: #ffff !important;
  line-height: 1;
}

@media (max-width: 980px) {
  .swiper-button-prev {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 15px !important;
    height: 20px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---primaryColor);
  }
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 15px !important;
    height: 20px !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(---primaryColor);
  }
  .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #ffff !important;
  }
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 10px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    color: #ffff !important;
    line-height: 1;
  }
}
