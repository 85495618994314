/* =========== Banner Section ======== */
.contact-section {
  position: relative;
  z-index: 1;
}

.contact-section .contact-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  height: 120px;
  background: url(../img/theam/bottom-2.png)
    center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}
.contactBanner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/banner/realestate-banner.jpg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}
.contactbannerTxt h2 {
  color: #fff;
  font-size: 50px;
  font-family: var(---ItalicFont);
  font-weight: 600;
  text-align: left;
}

.contactbannerTxt p {
  color: #fff;
  font-family: var(---SubHeading);
  text-align: left;
  font-size: 16px;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 768px) {
  .contactBanner {
    height: 60vh;
  }

  .contactbannerTxt {
    padding:20px;
    margin-top: 10px;
  }
  .contactbannerTxt h1 {
    color: #fff;
    font-size: 24px;
    font-family: var(---HeadFont);
    font-weight: 600;
    text-align: left;
  }
  .contactbannerTxt h2 {
    color: #fff;
    font-size: 24px;
    font-family: var(---HeadFont);
    font-weight: 600;
    text-align: left;
  }
  .contactbannerTxt p {
    color: #fff;
    font-family: var(---SubHeading);
    text-align: left;
    font-size: 16px;
  }
}

/* ============== Page Banner ============ */
.page-banner {
  position: relative;
  padding: 0;
  color: #ffffff;
  background: #0f2c36;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-banner .banner-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 120px;
  background: url(../img/theam/bottom-2.png) center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}

.page-banner .banner-inner {
  position: relative;
  display: block;
  z-index: 2;
}

.page-banner .inner-container {
  position: relative;
  padding: 290px 0px 230px;
}

.page-banner.ext-banner .inner-container {
  height: 820px;
}

.page-banner .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-banner .image-layer:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171b27;
  opacity: 0.5;
}

.page-banner h1 {
  position: relative;
  font-size: 54px;
  color: #ffffff;
  line-height: 1.2em;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: capitalize;
  margin: 0;
}

.page-banner .page-nav {
  position: relative;
  padding-top: 10px;
}

.page-banner .bread-crumb {
  position: relative;
  display: inline-block;
}

.page-banner .bread-crumb li {
  position: relative;
  float: left;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
  margin-right: 25px;
}

.page-banner .bread-crumb li:before {
  position: absolute;
  right: -25px;
  width: 25px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
  content: "-";
}

.page-banner .bread-crumb li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.page-banner .bread-crumb li:last-child:before {
  display: none;
}

.page-banner .bread-crumb li a {
  color: #ffffff;
  font-weight: 400;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.page-banner .bread-crumb li a:hover,
.page-banner .bread-crumb li.active {
  color: #ffffff;
}

/* ============= Contact form Section ========= */

.contact-section-two {
  position: relative;
  padding: 100px 0px 90px;
  background: #ffffff;
}

.contact-section-two .info-col {
  position: relative;
  z-index: 2;
}

.contact-section-two .info-col .inner {
  position: relative;
  display: block;
  max-width: 500px;
}

.contact-section-two .info-col .u-text {
  position: relative;
  display: block;
  color: #1b1c1e;
  font-size: 16px;
  line-height: 1.6em;
  margin: 0 0 55px;
}

.contact-section-two .info {
  position: relative;
}

.contact-section-two .info .info-block {
  position: relative;
  display: block;
  margin-bottom: 52px;
}

.contact-section-two .info .info-block .block-inner {
  position: relative;
  display: block;
  padding-left: 100px;
}

.contact-section-two .info .info-block .icon-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height:70px;
  padding: 10px 10px;
  text-align: center;
  border: 1px solid red;
  /* background: #1b1c1e; */
  color:var(---primaryColor);
  font-size: 40px;
  box-shadow: 0 0 2px var(---primaryColor) ;
  border-radius: 5px;
}

.contact-section-two .info .info-block h4 {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
  margin-bottom: 15px;
}

.contact-section-two .info .info-block .text {
  position: relative;
  display: block;
  color: #9b9fa6;
  /* line-height: 1.7em; */
}

.contact-section-two .info .info-block .text a {
  position: relative;
  color: #9b9fa6;
}

.contact-section-two .info .info-block .text a:hover {
  text-decoration: underline;
  color: #1b1c1e;
}

.contact-section-two .form-col {
  position: relative;
  margin-bottom: 50px;
}

.contact_num{
  display: flex;
  justify-content:flex-start;
  align-items: center;
}

.contactTxt2{
  margin-left: 30px;
}
.text h6{
  color: #111;
  margin-top: 10px;
}
@media (max-width: 750px) {
  .contact-section-two .info .info-block .block-inner {
    position: relative;
    display: block;
    padding-left: 65px;
  }
  
  .contact-section-two .info .info-block .icon-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 45px;
    height:45px;
    padding: 5px;
    text-align: center;
    background: #1b1c1e;
    color: #ffffff;
    font-size: 25px;
    box-shadow: 0 0 5px black;
  }
  .contact-section-two .info .info-block {
    position: relative;
    display: block;
    margin-bottom: 20px;
}
}
@media (max-width: 375px) {
  .contact_num{
    display:initial;
  }
  .contactTxt2{
    margin-left: 0px;
  }
}



.contact-section-two .form-box {
  position: relative;
  display: block;
  margin-top: -290px;
  padding: 70px 70px 40px;
  background: #ffffff;
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.07);
  z-index: 10;
}

.contact-section-two .form-box h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}

.contact-section-two .form-box .text {
  position: relative;
  display: block;
  color: #9b9fa6;
  font-size: 16px;
  line-height: 1.6em;
  margin: 0 0 40px;
}

.contact-section-two .form-box .form-group {
  margin-bottom: 30px;
  border: 1px solid gray;
}



.contact-form-two .form-group input[type="text"],
.contact-form-two .form-group input[type="email"],
.contact-form-two .form-group input[type="password"],
.contact-form-two .form-group input[type="tel"],
.contact-form-two .form-group input[type="url"],
.contact-form-two .form-group input[type="file"],
.contact-form-two .form-group input[type="number"],
.contact-form-two .form-group textarea,
.contact-form-two .form-group select {
  height: 52px;
  padding: 10px 20px;
  line-height: 30px;
}

.contact-form-two .form-group textarea {
  height: 170px;
}

.contact-form-two .form-group .theme-btn {
  min-width: 190px;
  display: inline-block;
}

.contact-form-two .form-group .theme-btn .btn-title {
  padding: 15px 25px;
  line-height: 30px;
  text-align: center;
}
@media (max-width: 990px) {
  .contact-section-two .form-box {
    position: relative;
    display: block;
    margin-top: 0px;
    padding: 70px 70px 40px;
    background: #ffffff;
    box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.07);
    z-index: 10;
  }
}

@media (max-width: 750px) {
  .contact-section-two .form-box {
    position: relative;
    display: block;
    margin-top: 0px;
    padding:20px;
    background: #ffffff;
    box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.07);
    z-index: 10;
  }
}

/* ======== Map Section =========== */
.contact-map {
  width: 100%;
  height: 100%;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  min-height: 500px;
}
