/* =========== Banner Section ======== */
.resort-section {
  position: relative;
  z-index: 1;
}
.resort-section .resort-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  height: 120px;
  background: url(../img/theam/bottom-2.png) center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}
.resortBanner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/banner/resort-banner.jpg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}
.resortbannerTxt h2 {
  color: #fff;
  font-size: 50px;
  font-family: var(---ItalicFont);
  font-weight: 600;
  text-align: left;
}

.resortbannerTxt p {
  color: #fff;
  font-family: var(---SubHeading);
  text-align: left;
  font-size: 16px;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 768px) {
  .resortBanner {
    height: 60vh;
  }

  .resortbannerTxt {
    padding: 20px;
    margin-top: 20px;
  }
  .resortbannerTxt h2 {
    color: #fff;
    font-size: 24px;
    font-family: var(---HeadFont);
    font-weight: 600;
    text-align: left;
  }
  .resortbannerTxt p {
    color: #fff;
    font-family: var(---SubHeading);
    text-align: left;
    font-size: 16px;
  }
}

/* ========== About Resort  Section ==== */
.resort-img {
  width: 100%;
  height: 100%;
  max-height: 550px;
  box-shadow: 0 0 3px gray;
  border-radius: 5px;
}
.resort-img img {
  width: 100%;
  height: 100%;
  background-color: #eee;
  transform: scale(0.9);
}
.resort-img:hover img {
  transform: scale(1);
}

.resortDetail-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.resortDetail-icon i {
  font-size: 40px;
  color: var(---primaryColor);
}
.resort-desc h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
}

@media (max-width: 990px) {
  .resort-desc h2 {
    margin-top: 20px;
  }
}

/* ======== Resort Counter Section ======== */
.resort-counter {
  position: relative;
  background: #edf0f5;
}

.resort-counter .pattern-top {
  bottom: 100%;
  margin-bottom: 0px;
}
.resort-counter .counter-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.resort-counter .counter-box .icon {
  position: relative;
  font-size: 45px;
  width: 85px;
  height: 85px;
  line-height: 85px;
  border-radius: 50px;
  background: #fff;
  text-align: center;
  color: var(---primaryColor);
  z-index: 1;
}

.resort-counter .counter-box .icon::before {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px dashed var(---primaryColor);
  border-radius: 50px;
  z-index: -1;
}

.resort-counter .counter-box .icon {
  font-size: 50px;
}

.resort-counter .counter-box .counter span{
  display: block;
  line-height: 1;
  color: #111;
  font-size: 30px;
  font-weight: 600;
}

.resort-counter .counter-box .title {
  color: #111;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
@media (max-width: 990px) {
  .resort-counter .counter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    position: relative;
    z-index: 1;
  }
  
  .resort-counter .counter-box .icon {
    position: relative;
    font-size: 45px;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    color: var(---primaryColor);
    z-index: 1;
  }
  
  .resort-counter .counter-box .icon::before {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px dashed var(---primaryColor);
    border-radius: 50px;
    z-index: -1;
  }
  .resort-counter .counter-box .icon {
    font-size: 30px;
  }
  
  .resort-counter .counter-box .counter span{
    display: block;
    line-height: 1;
    color: #111;
    font-size: 24px;
    font-weight: 600;
  }
  
  .resort-counter .counter-box .title {
    color: #111;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }
}


/* ===== Resort Room Section ====== */
.resort-room {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/room/room-deluxe.jpg");
  height: 65vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
}

/* ======== Resort Form Section ======= */

.resort-form {
  position: relative;
  padding: 100px 0px 90px;
  background: #ffffff;
}
.resort-form .form-box {
  position: relative;
  display: block;
  margin-top: -290px;
  padding: 70px 70px 40px;
  background: #ffffff;
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.07);
  z-index: 10;
}
.Resort-rooms h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}

.resort-form .form-box h2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: var(---HeadFont);
}

.resort-form .form-box .form-group {
  margin-bottom: 30px;
  border: 1px solid gray;
}

@media all and (max-width: 990px) {
  .resort-form .form-box {
    position: relative;
    display: block;
    margin-top: 0px;
    padding:30px;
    background: #ffffff;
    box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.07);
    z-index: 10;
  }
}

@media all and (max-width: 550px) {
  .resort-form .form-box {
    position: relative;
    display: block;
    padding:30px;
    background: #ffffff;
    box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.07);
    z-index: 10;
  }
}
