/* =========== Banner Section ======== */
.wedding-section {
  position: relative;
  z-index: 1;
}

.wedding-section .resort-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  height: 120px;
  background: url(../img/banner/home-banner.jpg)
    center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}
.weddingBanner {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/banner/wedding-banner.jpg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}
.weddingbannerTxt h2 {
  color: #fff;
  font-size: 50px;
  font-family: var(---ItalicFont);
  font-weight: 600;
  text-align: left;
}

.weddingbannerTxt p {
  color: #fff;
  font-family: var(---SubHeading);
  text-align: left;
  font-size: 16px;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 768px) {
  .weddingBanner {
    height: 60vh;
  }

  .weddingbannerTxt {
    padding: 20px;
    margin-top: 10px;
  }
  .weddingbannerTxt h2 {
    color: #fff;
    font-size: 24px;
    font-family: var(---HeadFont);
    font-weight: 600;
    text-align: left;
  }
  .weddingbannerTxt p {
    color: #fff;
    font-family: var(---SubHeading);
    text-align: left;
    font-size: 16px;
  }
}

/* ======== Resort Counter Section ======== */
.wedding-counter {
    padding:100px 0px 40px 0px;
  position: relative;
  background-color: #fff;
}

.wedding-counter .counter-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.wedding-counter .counter-box .icon {
  position: relative;
  font-size: 45px;
  width: 85px;
  height: 85px;
  line-height: 85px;
  border-radius: 50px;
  background: #fff;
  text-align: center;
  color: var(---primaryColor);
  z-index: 1;
}

.wedding-counter .counter-box .icon::before {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px dashed var(---primaryColor);
  border-radius: 50px;
  z-index: -1;
}

.wedding-counter .counter-box .icon {
  /* font-size: 50px; */
  width: 75px;
  height: 75px;
  /* padding: 20px;  */
}

.wedding-counter .counter-box .icon  img{ 
  width: 50%;
  height: 50%;
}

.wedding-counter .counter-box .counter span{
  display: block;
  line-height: 1;
  color: #111;
  font-size: 30px;
  font-weight: 600;
}

.wedding-counter .counter-box .title {
  color: #111;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

@media (max-width: 990px) {
  .wedding-counter .counter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    position: relative;
    z-index: 1;
  }
  
  .wedding-counter .counter-box .icon {
    position: relative;
    font-size: 45px;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    color: var(---primaryColor);
    z-index: 1;
  }
  
  .wedding-counter .counter-box .icon::before {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px dashed var(---primaryColor);
    border-radius: 50px;
    z-index: -1;
  }
  .wedding-counter .counter-box .icon {
    font-size: 30px;
  }
  
  .wedding-counter .counter-box .counter span{
    display: block;
    line-height: 1;
    color: #111;
    font-size: 24px;
    font-weight: 600;
  }
  
  .wedding-counter .counter-box .title {
    color: #111;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }
}


/* ============= Wedding Enquiry Section ======== */
.wedding-enquiry{
    background-image: linear-gradient(
        120deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      url("../img/banner/home-banner.jpg");
    height: 80vh;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
  }

  .wedding-enquiry .headTitle h2{
    color: #fff;
  }
  .wedding-enquiry .headTitle p{
    color: #fff;
  }
  .wedding-enquiry .form-btn{
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /* ============ Wedding Gallery ======= */
  .wedding-gallery {
    position: relative;
    background: #edf0f5;
    padding-bottom: 70px;
  }
  
  .wedding-gallery .pattern-top {
    bottom: 100%;
    margin-bottom: 0px;
  }

  @media all and (max-width: 485px) {
    .galleryHead h2:before{
      margin-top: 20px;
    }
  }