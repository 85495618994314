/* =========== Banner Section ======== */
.banner-section {
  position: relative;
  z-index: 1;
}

.banner-section .banner-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 100%;
  height: 120px;
  background: url(../img/theam/bottom-1.png)
    center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 5;
}
.banner-carousel .HomeBanner1 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/Clubhouse-20240113T064329Z-001/Clubhouse/Party_Lawn.jpeg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}
.banner-carousel .HomeBanner2 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/banner/home-banner.jpg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}
.banner-carousel .HomeBanner3 {
  background-image: linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("../img/Clubhouse-20240113T064329Z-001/Clubhouse/3.\ Dreammland\ Hotel\ Building.jpeg");
  height: 100vh;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shrink 4s infinite;
}

.bannerTxt h2 {
  color: #fff;
  font-size: 50px;
  font-family: var(---ItalicFont);
  font-weight: 600;
  text-align: left;
}

.bannerTxt p {
  color: #fff;
  font-family: var(---SubHeading);
  text-align: left;
  font-size: 16px;
}

@keyframes shrink {
  0% {
    background-size: 115% 115%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media (max-width: 768px) {
  .banner-carousel .HomeBanner1 {
    height: 65vh;
  }
  .banner-carousel .HomeBanner2 {
    height: 65vh;
  }
  .banner-carousel .HomeBanner3{
    height: 65vh;
  }

  .home-Txt {
    padding: 0px 20px 0px 40px;
  }
  .bannerTxt {
    padding:40px;
  }

  .bannerTxt h2 {
    color: #fff;
    font-size: 24px;
    font-family: var(---ItalicFont);
    font-weight: 600;
    text-align: left;
  }
  .banner-Txt p {
    color: #fff;
    font-family: var(---SubHeading);
    text-align: left;
    font-size: 16px;
  }
}

/* ======== About Section ======= */
.about-img{
	width: 100%;
	height: 100%;
}
.about-img img{
	width: 100%;
	height: 100%;
}

.home-about-txt h2{
  font-size: 26px;
  font-weight: 600;
    text-transform: capitalize;
    font-family: var(---HeadFont);
}
@media (max-width: 990px) {
  .home-about-txt h2{
    margin-top: 30px;
  }
}

/* ====== Services ===== */
.Service-sec {
  position: relative;
  background: #edf0f5;
}
.Service-sec .pattern-top {
  bottom: 100%;
  margin-bottom: -0px;
}
.services-icon {
  display: flex;
  justify-content:start;
  align-items: center;
  margin-bottom: -35px;
  margin-left: 15px;
  
  
}
.services-icon img {
  width: 70px;
  height: 70px;
  border-radius: 15%;
  background-color: #fff;
  padding: 10px;
  /* box-shadow: 2px 2px 2px gray; */
  border: 2px dashed var(---primaryColor);
}
.service-txt {
  box-shadow: 0 0 4px #808080;
  /* border: 1px solid gray; */
  padding: 50px 20px 10px 20px;
  border-radius: 7px;
  background-color: #fff;
  min-height: 240px;
  margin-bottom: 20px;
}

.service-txt h2{
	font-size: 24px;
  }

 /* =============== Activities Section =========== */
 .Activities-Sec{
    position:relative;
    padding: 140px 0px 50px;
    background: #ffffff;
    margin-bottom: 80px;
  }

  .home-activities-txt h2{
    font-size: 26px;
    font-weight: 600;
      text-transform: capitalize;
      font-family: var(---HeadFont);
  }
  .content-box {
    height: 300px; /* Adjust the height according to your needs */
    overflow-y: scroll;
  }
  
  .activitie-Img{
	display: flex;.testimonial-block .content {
    position: relative;
    display: block;
    padding: 40px 30px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    z-index: 1;
  }
	justify-content: center;
	align-items: end;
	position: relative;
  height: 310px;
  }
  
  .activitie-Img img{
	width: 100%;
	height: 100%;
  }
  .activities-txt{
	position: absolute;
  }
  .activities-txt h4{
	color: #fff;
	font-family: var(---HeadFont);
	text-transform: capitalize;
  }
  

/* =============== Testimonials Section =========== */

.testimonials-section {
  position: relative;
  background: #edf0f5;
  padding-bottom: 70px;
}

.testimonials-section .pattern-top {
    bottom: 100%;
    margin-bottom: 0px;
}

.testimonials-section.alt-padding {
  padding-bottom: 140px;
}

.testimonials-section .circles-two {
  left: 80px;
  bottom: 80px;
  width: 150px;
  height: 150px;
}

.testimonials-section .circles-two .c-1 {
  width: 150px;
  height: 150px;
}

.testimonials-section .circles-two .c-2 {
  top: -35px;
  left: auto;
  right: -35px;
  width: 100px;
  height: 100px;
}

.testimonials-section .dotted-pattern-3 {
  left: -35px;
  bottom: 100px;
}

.testimonials-section .tri-pattern-2 {
  left: 120px;
  bottom: 150px;
}

.testimonials-two .carousel-box {
  position: relative;
  display: block;
  top: -20px;
  z-index: 5;
}

.testimonial-block {
  position: relative;
  display: block;
}

.testimonial-block .inner {
  position: relative;
  display: block;
  text-align: center;
  margin: 20px 20px 0px;
}

@media (max-width:700px){
  .testimonial-block .content-box {
    position: relative;
    display: block;
    margin-bottom: 45px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
}

@media (min-width:700px){
  .testimonial-block .content-box {
    position: relative;
    display: block;
    margin-bottom: -44px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
}


.testimonial-block .content-box:before {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -40px;
  bottom: 0px;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.testimonial-block .content-box:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -16px;
  bottom: 0px;
  width: 32px;
  height: 10px;
  border: 5px solid #ffffff;
  z-index: 2;
}

.testimonial-block:hover .content-box:before,
.testimonial-block:hover .content-box .content {
  border-color: rgba(0, 0, 0, 0.025);
}

.testimonial-block .content {
  position: relative;
  display: block;
  padding: 40px 30px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}

.testimonial-block:hover .content-box {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.05);
}

.testimonial-block .content .text {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 1.6em;
  color: #9b9fa6;
}

.testimonial-block .info {
  position: relative;
  display: block;
  line-height: 1.5em;
  color: #666666;
  min-height: 90px;
}

.testimonial-block .info .image {
  position: relative;
  left: 0;
  top: 0;
  width: 75px;
  height: 75px;
  margin: 0 auto 20px;
  border-radius: 50%;
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.025);
}

.testimonial-block .info .image img {
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.testimonial-block .info .name {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 1.5em;
  color: #1b1c1e;
  font-weight: 500;
  text-transform: capitalize;
}

.testimonial-block .info .designation {
  position: relative;
  display: block;
  padding-top: 5px;
  font-size: 16px;
  line-height: 1.5em;
  color: #9b9fa6;
  font-weight: 400;
  text-transform: capitalize;
}

.testimonials-section .carousel-box .owl-theme .owl-nav {
  display: none;
}

.testimonials-section .owl-dots {
  position: relative;
  display: block;
  text-align: center;
  width: 100%;
  padding-top: 50px;
}
.testimonials-section-two {
  position: relative;
  padding: 140px 0px 110px;
  background: #edf0f5;
}

.testimonials-section-two .circles {
  right: 85px;
  top: 75px;
}

.testimonials-section-two .circles .c-1 {
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.testimonials-section-two .circles .c-2 {
  background: rgba(255, 255, 255, 0.6);
  opacity: 1;
}

.testimonials-section-two .dotted-pattern-5 {
  left: -160px;
  top: 110px;
}

.testimonials-section-two .tri-pattern-4 {
  left: 40px;
  top: 240px;
}

.testimonials-section-two .pattern-bottom {
  top: 100%;
  margin-top: -10px;
}

.testimonial-block-two {
  position: relative;
  display: block;
  padding-left: 70px;
}

.owl-theme .active .testimonial-block-two {
  opacity: 1;
  visibility: visible;
}

.testimonial-block-two .text-col {
  position: relative;
}

.testimonial-block-two .text-col .inner {
  position: relative;
  display: block;
  padding-top: 110px;
  padding-left: 40px;
}

.testimonial-block-two .image-col {
  position: relative;
}

.testimonial-block-two .image-col .inner {
  position: relative;
  display: block;
  padding-right: 30px;
}

.testimonial-block-two .quote-icon {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 1em;
  margin-bottom: 15px;
  color: #1b1c1e;
}

.testimonial-block-two .quote-icon span {
  position: relative;
  display: inline-block;
  transform: rotate(180deg);
}

.testimonial-block-two .text {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.5em;
  color: #1b1c1e;
}

.testimonial-block-two .image {
  position: relative;
  width: 100%;
  box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.1);
}

.testimonial-block-two .image img {
  display: block;
  width: 100%;
}

.testimonial-block-two .info {
  position: relative;
  padding-top: 20px;
}

.testimonial-block-two .info .name {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 1.5em;
  color: #1b1c1e;
  font-weight: 500;
  text-transform: capitalize;
}

.testimonial-block-two .info .designation {
  position: relative;
  display: block;
  padding-top: 5px;
  font-size: 16px;
  line-height: 1.5em;
  color: #9b9fa6;
  font-weight: 400;
  text-transform: capitalize;
}
